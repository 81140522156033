<!--新增/编辑收货地址 @zjf-2021-01-06-->
<template>
  <div class="invoice_operate_modal">
    <el-dialog :title="L['发票信息']" v-model="modalVisible" @close="close_dialog" top="80px">
      <div class="lay_out">
        <el-form :model="invoice_info.data" :rules="invoice_info.data.titleType == 1? rulesPersonal : rulesCompany"
                 ref="ruleForm">
          <div class="part_title">
            <span>{{ L['票据信息'] }}</span>
          </div>
          <el-form-item :label="L['抬头类型'] + '：'" :label-width="formLabelWidth" prop="titleType">
            <el-radio-group v-model="invoice_info.data.titleType" fill="#006DE4" @change="changeTitleType">
              <el-radio label="1">{{ L['个人'] }}</el-radio>
              <el-radio label="2">{{ L['公司'] }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="L['发票介质'] + '：'" :label-width="formLabelWidth" prop="invoicePaperType">
            <el-radio-group v-model="invoice_info.data.invoicePaperType" fill="#006DE4" @change="changeTitleType">
              <el-radio label="1">{{ L['电子发票（开票快）'] }}</el-radio>
              <el-radio label="2">{{ L['纸质发票'] }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="L['发票类型'] + '：'" :label-width="formLabelWidth" prop="invoiceType">
            <el-radio-group v-model="invoice_info.data.invoiceType" fill="#006DE4">
              <el-radio label="1">{{ L['普通发票'] }}</el-radio>
              <el-radio v-if="invoice_info.data.titleType == 2 && invoice_info.data.invoicePaperType == 2" label="2">
                {{ L['增值税专用发票'] }}
              </el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item :label="L['发票抬头'] + '：'" :label-width="formLabelWidth" prop="invoiceTitle">
            <el-input v-if="invoice_info.data.titleType == 1" v-model="invoice_info.data.invoiceTitle"
                      autocomplete="off" :placeholder="L['发票抬头']" clearable></el-input>
            <el-input v-else v-model="invoice_info.data.invoiceTitle" autocomplete="off" :placeholder="L['某某某科技有限公司']"
                      clearable></el-input>
          </el-form-item>

          <div v-if="invoice_info.data.titleType == 2">
            <el-form-item :label="L['纳税人识别号'] + '：'" :label-width="formLabelWidth" prop="taxCode">
              <el-input v-model="invoice_info.data.taxCode" autocomplete="off" :placeholder="L['纳税人识别号']"
                        clearable></el-input>
            </el-form-item>
          </div>

          <el-form-item :label="L['发票内容'] + '：'" :label-width="formLabelWidth" prop="invoiceContent">
            <div class="box_invoice_content flex">
              <div class="invoice_content_item" :class="{active: invoice_info.data.invoiceContent == 1}"
                   @click="handleChooseContent(1)">
                <span>{{ L['商品明细'] }}</span>
                <img v-if="invoice_info.data.invoiceContent == 1" :src="invoice_selected" alt/>
              </div>
              <div class="invoice_content_item" :class="{active: invoice_info.data.invoiceContent == 2}"
                   @click="handleChooseContent(2)">
                <span>{{ L['商品类别'] }}</span>
                <img v-if="invoice_info.data.invoiceContent == 2" :src="invoice_selected" alt/>
              </div>
            </div>
          </el-form-item>
          <div class="part_title" v-if="invoice_info.data.invoicePaperType == 1">
            <span>{{ L['邮箱信息'] }}</span>
          </div>
          <el-form-item :label="L['邮箱名称'] + '：'" :label-width="formLabelWidth" prop="receiverEmail"
                        v-if="invoice_info.data.invoicePaperType == 1">
            <el-input v-model="invoice_info.data.receiverEmail" autocomplete="off" :placeholder="L['请输入邮箱名称']"
                      clearable></el-input>
          </el-form-item>
          <div class="part_title" v-if="invoice_info.data.invoicePaperType == 2">
            <span>{{ L['邮寄信息'] }}</span>
          </div>
          <el-form-item :label="L['收货地址'] + '：'" :label-width="formLabelWidth" prop="receiverAddress"
                        v-if="invoice_info.data.invoicePaperType == 2">
            <el-select v-model="invoice_info.data.receiverAddress" :placeholder="L['请选择收货地址']"
                       :popper-append-to-body="false" style="width: 100%" @change="test">
              <el-option
                  v-for="item in addressList.data"
                  :key="item.addressId"
                  :label="item.memberName+','+item.addressAll+','+item.detailAddress"
                  :value="item.memberName+','+item.telMobile+','+item.addressAll+item.detailAddress">
              </el-option>
              <div class="dialog_add_address" @click="addNewAddress">{{ L['新增收货地址'] }}</div>
            </el-select>
          </el-form-item>

        </el-form>
      </div>
      <template #footer>
                <span class="dialog-footer">
                  <el-button @click="close_dialog">{{ L['取 消'] }}</el-button>
                  <el-button type="primary" @click="addInvoice('ruleForm')" :loading="btnLoading">{{
                      L['保 存']
                    }}</el-button>
                </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {ref, reactive, getCurrentInstance, watchEffect} from "vue";
import areaData from "../assets/area.json";
import {ElMessage} from "element-plus";

export default {
  name: "AddInvoice",
  components: {},
  props: ["visibleFlag", "addressList"],
  setup(props, {emit}) {
    const {proxy} = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const invoice_selected = require("../assets/buy/invoice_selected.png");
    const formLabelWidth = "170px";
    const modalVisible = ref(true);
    const form = reactive({data: {}});
    const areaDataArray = areaData;
    const cascaderProps = {label: "regionName", value: "regionCode"};
    const ruleForm = ref(null);
    const cascaderAddr = ref(null);
    const btnLoading = ref(false)
    const type = ref(props.type)
    // const addressList = reactive({data: []});
    const select_invoice_info = reactive({
      invoiceId: "",
      invoiceContent: "",
      invoiceTitle: ""
    });
    const invoice_info = reactive({
      data: {
        //新增发票信息
        invoiceContent: '1', //发票内容：1-商品明细；2-商品类别
        invoiceTitle: "", //发票抬头(通用信息）
        invoiceType: '1', //发票类型：1-普通发票；2-增值税发票
        invoicePaperType: '1', //发票类型：1-电子发票；2-纸质发票
        isDefault: false, //是否默认发票：0-非默认发票，1-默认发票
        receiverAddress: "", //收票人详细地址(通用信息）
        receiverEmail: "", //	收票邮箱(通用信息）
        receiverMobile: "", //收票人手机号(通用信息）
        receiverName: "", //收票人姓名(通用信息）
        registerAddr: "", //注册地址(专用发票）
        registerPhone: "", //注册电话(专用发票）
        taxCode: "", //纳税人识别号(通用信息）
        titleType: '2', //抬头类型：1-个人；2-公司
        companyName: "" //单位名称
      }
    });
    // 重置发票信息
    const resetVoiceInfo = () => {
      invoice_info.data.invoiceContent = '1';
      invoice_info.data.invoiceTitle = "";
      invoice_info.data.invoiceType = '1';
      invoice_info.data.invoicePaperType = '1';
      invoice_info.data.isDefault = false;
      invoice_info.data.receiverAddress = "";
      invoice_info.data.receiverEmail = "";
      invoice_info.data.receiverMobile = "";
      invoice_info.data.receiverName = "";
      invoice_info.data.registerAddr = "";
      invoice_info.data.registerPhone = "";
      invoice_info.data.taxCode = "";
      invoice_info.data.titleType = '2';
      invoice_info.data.companyName = "";
    };

    const rulesPersonal = {
      titleType: [
        {required: true, message: L["请选择抬头类型"], trigger: "change"},
      ],
      invoicePaperType: [{required: true, message: L["请选择发票介质"], trigger: "change"}],
      invoiceTitle: [{required: true, message: L["发票抬头"], trigger: "blur"}],
      invoiceContent: [{required: true, message: L["请选择发票内容"], trigger: "change"}],
      receiverEmail: [{required: true, message: L["请输入邮箱名称"], trigger: "blur"}],
      receiverAddress: [{required: true, message: L["请选择收货地址"], trigger: "change"}],
    }; //输入校验

    const rulesCompany = {
      titleType: [
        {required: true, message: L["请选择抬头类型"], trigger: "change"},
      ],
      invoicePaperType: [{required: true, message: L["请选择发票介质"], trigger: "change"}],
      invoiceTitle: [{required: true, message: L["某某某科技有限公司"], trigger: "blur"}],
      invoiceContent: [{required: true, message: L["请选择发票内容"], trigger: "change"}],
      taxCode: [{required: true, message: L["纳税人识别号"], trigger: "blur"}],
      receiverEmail: [{required: true, message: L["请输入邮箱名称"], trigger: "blur"}],
      receiverAddress: [{required: true, message: L["请选择收货地址"], trigger: "change"}],
    }; //输入校验

    const handleChooseContent = (val) => {
      invoice_info.data.invoiceContent = val;
    };
    // 新增地址
    const addNewAddress = () => {
      emit('showAddAddress');
    };
    // 发票类型
    const changeTitleType = () => {
      ruleForm.value.clearValidate();
    };
    const test =(val)=>{
      console.log(val)
    }
    //增加发票
    const addInvoice = () => {
      btnLoading.value = true
      ruleForm.value.validate(valid => {
        if (valid) {
          let param = {};
          if (invoice_info.data.isDefault) {
            param.isDefault = 1;
          } else {
            param.isDefault = 0;
          }
          if(invoice_info.data.titleType == 2) {
            param.taxCode = invoice_info.data.taxCode;
          }
          if (invoice_info.data.invoicePaperType == 1) {
            param.receiverEmail = invoice_info.data.receiverEmail
          }else if (invoice_info.data.invoicePaperType == 2) {
            param.receiverName = invoice_info.data.receiverAddress.split(',')[0];
            param.receiverMobile = invoice_info.data.receiverAddress.split(',')[1];
            param.receiverAddress = invoice_info.data.receiverAddress.split(',')[2];
          }
          param.titleType = invoice_info.data.titleType;
          param.invoiceType = invoice_info.data.invoiceType;
          param.invoiceContent = invoice_info.data.invoiceContent;
          param.invoicePaperType = invoice_info.data.invoicePaperType;
          param.invoiceTitle = invoice_info.data.invoiceTitle;
          proxy
              .$post("v3/member/front/memberInvoice/add", param)
              .then(res => {
                if (res.state == 200) {
                  if (res.state == 200) {
                    select_invoice_info.invoiceTitle = invoice_info.data.invoiceTitle;
                    select_invoice_info.invoiceContent = invoice_info.data.invoiceContent;
                    select_invoice_info.invoiceId = res.data;
                    resetVoiceInfo();
                    emit("refreshInvoice", select_invoice_info);
                    emit("close");
                    ElMessage.success({
                      message: res.msg,
                      type: "success"
                    });
                  }
                } else {
                  ElMessage.error(res.msg);
                }
              })
              .catch(() => {
                //异常处理
              });
        } else {
          btnLoading.value = false
          return false;
        }
      });
    };
    const close_dialog = () => {
      emit("close");
      document.body.style.overflow = 'visible'
    };
    return {
      L,
      formLabelWidth,
      modalVisible,
      // addressList,
      invoice_info,
      invoice_selected,
      resetVoiceInfo,
      handleChooseContent,
      form,
      areaDataArray,
      cascaderProps,
      rulesPersonal,
      rulesCompany,
      changeTitleType,
      ruleForm,
      addInvoice,
      cascaderAddr,
      addNewAddress,
      close_dialog,
      btnLoading,
      type,
      test
    };
  }
};
</script>

<style lang="scss">
.el-cascader-menu__wrap {
  height: 204px !important;
}

.dialog_add_address {
  height: 34px;
  font-size: 12px;
  font-weight: 400;
  color: #1E7EDF;
  line-height: 34px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: var(--el-background-color-base);
  }
}

.invoice_operate_modal {
  .lay_out {
    padding: 20px 120px;
  }

  .part_title {
    position: relative;
    padding-left: 9px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      width: 2px;
      height: 10px;
      background: $colorMain;
    }

    span {
      height: 21px;
      font-size: 15px;
      font-weight: 500;
      color: #303030;
      line-height: 21px;
    }
  }

  .box_invoice_content {
    align-items: center;

    .invoice_content_item {
      position: relative;
      background: #FFFFFF;
      padding: 0 11px;
      border: 1px solid #dddddd;
      cursor: pointer;

      &.active {
        border: 1px solid #EA1515;
      }

      & + .invoice_content_item {
        margin-left: 20px;
      }

      span {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #303030;
        line-height: 20px;
      }

      img {
        width: 14px;
        height: 14px;
        position: absolute;
        right: -1px;
        bottom: -1px;
      }
    }
  }

  .el-dialog {
    width: 800px !important;
  }

  .el-cascader {
    width: 100% !important;
  }

  .el-checkbox {
    .el-checkbox__input {
      margin-top: -4px;
    }
  }

  .el-button--primary {
    color: #fff;
    background-color: $colorMain;
    border-color: $colorMain;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: $colorMain;
    border-color: $colorMain;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $colorMain;
  }

  .el-button {
    padding: 9px 20px;
  }

  .el-dialog__body {
    padding: 20px;
  }

  .el-form-item {
    margin-bottom: 15px;
  }

  .el-form-item__content {
    position: relative;
  }

  .el-form-item__error {
    position: absolute;
    top: -2px;
    right: 0;
    display: inline-block;
    width: auto;
    z-index: 2;
    background: #fff;
    left: auto;
    padding: 0 3px;
  }

  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #ccc;
    font-size: 12px;
  }

  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #ccc;
    font-size: 12px;
  }

  input:-ms-input-placeholder {
    /* IE 10+ */
    color: #ccc;
    font-size: 12px;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    color: #ccc;
    font-size: 12px;
  }

  textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #ccc;
    font-size: 12px;
  }

  textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #ccc;
    font-size: 12px;
  }

  textarea:-ms-input-placeholder {
    /* IE 10+ */
    color: #ccc;
    font-size: 12px;
  }

  textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #ccc;
    font-size: 12px;
  }

  .el-input {
    --el-input-focus-border: #e2231a;
  }

  .el-cascader-node.in-active-path {
    color: #e2231a !important;
  }
}
</style>
