<template>
  <div class="top_order_info flex_row_start_center">
    <div class="flex_row_start_center">
      <div class="top_logo">
        <div :style="`background-image:url(${configInfo.main_site_logo})`"></div>
      </div>
<!--      <div class="big-title">-->
<!--        <router-link tag="a" :to="`/index`">-->
<!--          <img src="../../assets/goods/biaoti@2x.png" alt="" />-->
<!--        </router-link>-->
<!--      </div>-->
    </div>

    <div class="top_info_progress flex_row_end_center">

      <div class="progress_item" v-if="pre_params.data.ifcart=='true'">
        <div class="progress flex_row_center_center">
          <span class="active">1</span>
          <div class="progress_line">
            <div :class="{content:true,active:true}"></div>
          </div>
        </div>
        <p>{{L['购物车']}}</p>
      </div>

      <div class="progress_item">
        <div class="progress flex_row_center_center">
          <span class="active">{{pre_params.data.ifcart=='true'?2:1}}</span>
          <div class="progress_line">
            <div :class="{content:true,active:false,current:false}"></div>
          </div>
        </div>
        <p style="margin-left: -11px">{{L['确认订单']}}</p>
      </div>

      <div class="progress_item">
        <div class="progress flex_row_center_center">
          <span :class="{active:false}">{{pre_params.data.ifcart=='true'?3:2}}</span>
          <div class="progress_line">
            <div :class="{content:true,active:false}"></div>
          </div>
        </div>
        <p>{{L['付款']}}</p>
      </div>

      <div v-if="curLang == 'zh'" class="progress_item" style="margin-left: -32px">
        <div class="progress flex_row_end_center">
          <span :class="{active:false}">{{pre_params.data.ifcart=='true'?4:3}}</span>
        </div>
        <p>{{L['支付成功']}}</p>
      </div>

      <div v-else class="progress_item" style="margin-left: -78px">
        <div class="progress flex_row_end_center">
          <span :class="{active:false}">{{pre_params.data.ifcart=='true'?4:3}}</span>
        </div>
        <p>{{L['支付成功']}}</p>
      </div>

    </div>
  </div>


  <div class="order_lay" v-loading="pageLoading" element-loading-spinner="el-icon-loading">
    <div class="confirm_order_container">
      <div class="receive_info" v-if="virtual.isVG==1">
        <div class="receive_top">
            <div class="receive_info_title">
                <span>{{L['收货信息']}}</span>
            </div>
            <div v-if="orderAddress.data.memberName" class="receive_top_add flex_row_center_center" @click="addAddress">
                <img src="../../assets/goods/tianjia@2x.png" alt="" />
                <span>{{L['新增收货地址']}}</span>
            </div>
        </div>
        <div v-if="orderAddress.data.memberName" class="receive_info_content flex_row_start_center">
          <div class="content_left flex_column_center_start">
              <div class="personal_info">
                  <span class="member">{{orderAddress.data.memberName}}</span>
                  <span>{{orderAddress.data.telMobile}}</span>
              </div>
            <span :title="orderAddress.data.addressAll+orderAddress.data.detailAddress"
                  class="address_detail overflow_ellipsis">{{orderAddress.data.addressAll}}
            {{orderAddress.data.detailAddress}}</span>
          </div>
<!--            <div class="content_right flex_column_center_center">-->
<!--                <div class="replace_address pointer" @click="changeAddress">{{L['更换地址']}}</div>-->
<!--            </div>-->
        </div>
        <div v-else class="receive_info_content_add flex_row_center_center" @click="addAddress">
          <div class="add_address nomartop pointer flex_row_center_center">
              <img src="../../assets/goods/tianjia@2x.png" alt="" />
              <span>{{L['新增收货地址']}}</span>
          </div>
        </div>
          <div class="all_address_list" v-if="orderAddress.data.memberName">
              <div class="show_all">
                  <span @click="chooseOther">{{L['使用其它地址']}}</span>
                  <img :class="{show: showOtherAddr}" src="../../assets/goods/gengduo@2x.png" alt="" />
              </div>
              <div class="show_address_list" v-if="showOtherAddr">
                  <div class="address_item flex" v-for="(item,index) in address_list.data" :key="index" @click="chooseAddress(index)">
                      <img class="icon_radio" v-if="orderAddress.data.addressId == item.addressId" src="../../assets/buy/xuanzhe@2x.png" />
                      <img class="icon_radio" v-else src="../../assets/buy/weixuanze@2x.png" />
                      <span>{{item.memberName}}  {{item.telMobile}}  {{item.detailAddress+item.detailAddress}}</span>
                  </div>
              </div>
          </div>
      </div>

      <!-- 预留信息 -->
      <div class="pre_message" v-else-if="virtual.isVG==2&&virtual.virtualPre.length">
        <div class="pre_message_title">
          <span>{{'预留信息'}}</span>
        </div>
        <div class="pre_message_info">
          <table>
            <tr v-for="(item,index) in virtual.virtualPre" :key="index">
              <td>
                <div class="tag_pre"><strong v-if="item.isRequired==1">*</strong><span>{{item.reserveName}}：</span></div>
              </td>
              <td v-if="item.reserveType==1||item.reserveType==3">
                <el-input id="pre_tag_input" v-model="item.reserveValue" :placeholder="`请输入${item.reserveName}`"
                          type="text" :maxlength="item.reserveType==1?11:50" clearable :key="index" @input="preMsgErr.index=-1">
                </el-input>
              </td>
              <td v-else-if="item.reserveType==5">
                <el-input id="pre_tag_input" v-model="item.reserveValue" :placeholder="`请输入${item.reserveName}`"
                          type="text" :maxlength="30" clearable :key="index" @input="preMsgErr.index=-1">
                </el-input>
              </td>
              <td v-else>
                <el-input id="pre_tag_input" v-model="item.reserveValue" :placeholder="`请输入${item.reserveName}`"
                          type="text" :maxlength="item.reserveType==2?18:50" clearable :key="index" @input="preMsgErr.index=-1">
                </el-input>
              </td>
              <td v-if="index == preMsgErr.index">
                <span class="preMsgErr">{{preMsgErr.errMsg}}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- 预留信息 -->
      <div class="goods_list_title flex_row_start_center">
        <div class="goods_list_title_item">{{L['店铺宝贝']}}</div>
        <div class="goods_list_title_item">{{L['单价']}}</div>
        <div class="goods_list_title_item">{{L['数量']}}</div>
        <div class="goods_list_title_item">{{L['小计']}}</div>
      </div>
      <div v-for="(storeItem,index) in goods_data.data" :key="index" class="store_item">
        <div class="store_name">
          <span>{{L['店铺']}}：{{storeItem.storeName}}</span>
        </div>
        <div class="good_container">
          <div v-for="(goodItem,index) in storeItem.productList" :key="index" class="good_item flex_row_start_center"
               :class="{errorGoods: goodItem.errorGoods}">
            <div class="good_info flex_row_start_center">
              <div class="good_image_con">
                <div class="virtual_tag" v-if="false">虚拟</div>
                <img class="good_image" :src="goodItem.image" alt />
              </div>
              <div class="good_info_text">
                <div class="good_name overflow_ellipsis_two text-decoration" v-if="goodItem.errorGoods">{{goodItem.goodsName}}</div>
                <div class="good_name overflow_ellipsis_two" v-else>{{goodItem.goodsName}}</div>
                <div class="good_spec overflow_ellipsis text-decoration" v-if="goodItem.errorGoods">{{goodItem.specValues}}</div>
                <div class="good_spec overflow_ellipsis" v-else>{{goodItem.specValues}}</div>
              </div>
            </div>
            <div class="good_price text-decoration" v-if="goodItem.errorGoods">¥{{goodItem.price}}</div>
            <div class="good_price" v-else>¥{{goodItem.price}}</div>
            <div class="num text-decoration" v-if="goodItem.errorGoods">{{goodItem.buyNum}}</div>
            <div class="num" v-else>{{goodItem.buyNum}}</div>
            <div class="total_price text-decoration" v-if="goodItem.errorGoods">¥{{Number(goodItem.price*goodItem.buyNum).toFixed(2)}}</div>
            <div class="total_price" v-else>¥{{Number(goodItem.price*goodItem.buyNum).toFixed(2)}}</div>
          </div>
        </div>
        <div class="store_coupon invoice flex">
          <div class="part flex">
            <div class="store_coupon_title">
              <span>{{L['发票信息']}}：</span>
            </div>
            <div class="invoice_con flex">
              <div class="no_select_invoice">
                <div class="box-choose flex_row_start_center">
                  <img :src="not_select" v-if="select_invoice_info.invoiceId==''" alt @click="showVoiceDialog" />
                  <img v-else :src="selected" alt />
                  <span>{{L['开具发票']}}</span>
                </div>
                <div class="invoice_info" v-if="select_invoice_info.invoiceId">
                  <span class="invoice_name">{{select_invoice_info.invoiceTitle}}</span>
                  <span class="invoice_type">{{select_invoice_info.invoiceContent}}</span>
                  <span @click="showVoiceDialog" class="choose">{{L['修改']}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="part flex">
            <div class="store_coupon_title flex_row_start_center">
              <span>{{L['优惠券']}}：</span>
              <div class="store_coupon_con flex_row_start_center" style="align-self: center">
                <span class="title" v-if="storeItem.availableCouponList.length==0">{{L['暂无可用优惠券']}}</span>
                <el-select class="voice_select" v-else v-model="couponCode[index]" @change="changeCoupon(storeItem,index)"
                           placeholder="请选择">
                  <el-option :value="L['不使用优惠券']">{{L['不使用优惠券']}}</el-option>
                  <el-option v-for="(couponItem,index) in storeItem.availableCouponList" :key="index"
                             :label="couponItem.couponName+(couponItem.couponType == 2?`(${couponItem.value}折)`:`(优惠${couponItem.value}元)`)"
                             :value="couponItem.couponCode"></el-option>
                </el-select>
              </div>
            </div>
            <div class="invoice_con flex freight">
              <span class="red">-￥{{storeItem.totalDiscount}}</span>
            </div>
          </div>
        </div>
        <div class="store_coupon flex message">
            <div class="part flex">
                <div class="store_message_title">
                    <span>{{L['给商家留言']}}：</span>
                </div>
                <div class="store_coupon_con2 flex_row_start_center">
                    <el-input maxlength="100" :rows="3" v-model="storeItem.remark" type="textarea" :placeholder="L['给商家留言']" show-word-limit />
                </div>
            </div>
            <div class="part flex">
              <div class="store_coupon_title">
                <span>{{L['运费']}}：</span>
              </div>
              <div class="invoice_con flex freight">
                <span class="red">￥{{storeItem.expressFee}}</span>
              </div>
            </div>
        </div>
        <div class="settle_info">
          <div class="total_money">
            <span>{{L['店铺合计(含运费)']}}：</span>
            <span>¥{{(Number(storeItem.goodsAmount)+Number(storeItem.expressFee)).toFixed(2)}}</span>
          </div>
        </div>
      </div>
      <div class="receive_info platform_discount">
        <div class="receive_info_title">
          <span>{{L['平台优惠券']}}</span>
        </div>
        <div class="platform_discount_con flex_column_center_start">
          <div class="flex_row_start_center">
            <span class="title" v-if="order_settle_info.platformCouponList.length==0">{{L['暂无可用优惠券']}}</span>
            <el-select class="voice_select" v-else v-model="platformCouponCode" @change="confirmOrder(2)"
                       placeholder="请选择">
              <el-option :value="L['不使用优惠券']">{{L['不使用优惠券']}}</el-option>
              <el-option v-for="(couponItem,index) in order_settle_info.platformCouponList" :key="index"
                         :label="couponItem.couponName+`(优惠${couponItem.value}元)`" :value="couponItem.couponCode"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="order_total_settle">
        <div class="box-order-info">
          <div class="order_price">
            <span>{{L['实付款']}}：</span>
            <span class="order_price_num">¥ {{order_settle_info.totalAmount}}</span>
          </div>
          <div class="order_addr">
            <span>{{L['寄送至']}}：</span>
            <span class="order_price_num">{{orderAddress.data.addressAll}}{{orderAddress.data.detailAddress}}</span>
          </div>
          <div class="order_addr">
            <span>{{L['收货人']}}：</span>
            <span class="order_price_num">{{orderAddress.data.memberName}}</span>
          </div>
        </div>
        <div class="btn-group flex">
          <div class="goback" @click="goBack" style="cursor: pointer;">{{L['返回购物车']}}</div>
          <div class="goBuy" v-if="isBottomShow" @click="checkOrder" style="cursor: pointer;">{{L['提交订单']}}</div>
          <div class="goBuy" v-else style="background: #898989;">{{L['提交订单']}}</div>
        </div>
      </div>
      <!-- 发票选择——编辑 start -->
      <el-dialog :title="L['发票信息']" v-model="edit_voice_dialog" customClass="voice_width" :before-close="handleClose"
                 lock-scroll="false">
        <p class="voice_list_title">{{L['选择发票抬头']}}：</p>
        <div class="voice_list">
            <el-select v-model="select_invoice_str" :placeholder="L['请选择']" :popper-append-to-body="false" style="width: 100%"
                       :no-data-text="L['暂无数据']" @change="handleChangeInvoice">
                <el-option
                    v-for="item in invoice_list.data"
                    :key="item.invoiceId"
                    :label="`${item.invoiceTitle}  ${item.invoiceContent == 1? L['商品明细'] : L['商品类别']}  ${item.invoiceType == 1? L['普通发票'] : L['增值税专用发票']}`"
                    :value="`${item.invoiceTitle},${item.invoiceContent},${item.invoiceId}`">
                </el-option>
            </el-select>
            <div class="voice_btn_group flex_row_center_center">
                <div class="add_voice_btn" @click="removeAddvoice">{{L['暂不开发票']}}</div>
                <div class="add_voice_btn add_voice_btn3" @click="showAddvoice">{{L['新增发票']}}</div>
            </div>
        </div>
      </el-dialog>
      <!-- 发票选择——编辑 end -->
      <!-- 商品无货缺货弹窗 start -->
      <el-dialog :title="no_good_info.data.stateValue" v-model="out_stock_dialog" customClass="out_store_width"
                 :before-close="handleClose" lock-sroll="false">
        <div class="out_stock_dialog">
          <div v-for="(outStockItem,index) in no_good_info.data.productList" :key="index" class="good_item flex">
            <img :src="outStockItem.image" alt />
            <div class="good_info">
              <span class="good_name overflow_ellipsis">{{outStockItem.goodsName}}</span>
              <div class="spec_num">
                <span class="good_spec">{{outStockItem.specValues}}</span>
                <span class="good_num">*{{outStockItem.buyNum}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="btn_con flex_row_center_center" v-if="no_good_info.data.state==2">
          <div class="return" @click="returnLastPage">{{L['返回']}}</div>
          <div class="remove" @click="clearFailureGoods">{{L['移除无货商品']}}</div>
        </div>
        <div class="btn_con flex_row_center_center" v-else>
          <div class="return red" @click="returnLastPage">{{L['返回']}}</div>
        </div>
      </el-dialog>
      <!-- 商品无货缺货弹窗 end -->
      <!-- 地址选择弹窗 start -->
      <el-dialog :title="L['更换地址']" v-model="show_select_address" customClass="select_address_width" :before-close="handleClose"
                 lock-sroll="false">
        <div class="out_stock_dialog address_con">
          <div v-for="(item,index) in address_list.data" :key="index" @click="selectAddress(index)"
               :class="{address_item:true, flex_column_start_start:true, select:current_address_index==index}">
            <span>{{L['收货人']}}：{{item.memberName}}</span>
            <span>{{L['联系方式']}}：{{item.telMobile}}</span>
            <div class="address_text" :title="item.addressAll+item.detailAddress">
              <span>{{L['收货地址']}}：</span>
              <span class="overflow_ellipsis_clamp2">{{item.addressAll}} {{item.detailAddress}}</span>
            </div>
            <img v-if="current_address_index==index" class="selected" :src="invoice_selected" alt />
          </div>
        </div>
        <div class="btn_con flex_row_center_center">
          <div class="remove red" @click="confirmChangeAddress()">{{L['提交修改']}}</div>
        </div>
      </el-dialog>
      <!-- 地址选择弹窗 end -->


      <SldAddressOperation v-if="addressVisibleFlag" @close="close" @refreshAddress="refreshAddress" :visibleFlag="true"
                           :type="type" />
        <AddInvoice v-if="add_invoice_content" :addressList="address_list" @close="closeDia" @refreshInvoice="refreshInvoice" @showAddAddress="showAddAddress" :visibleFlag="true" />
    </div>
  </div>
</template>

<script>
  import { reactive, getCurrentInstance, ref, onMounted, onUnmounted, watch, computed } from "vue";
  import {
    ElDialog,
    ElInput,
    ElMessage,
    ElSelect,
    ElOption
  } from "element-plus";
  import { useRoute, useRouter } from "vue-router";
  import SldAddressOperation from "../../components/AddressOperation";
  import AddInvoice from "../../components/AddInvoice";
  import { useStore } from 'vuex'
  import {goOtherApp} from "@/utils/goOtherApp";
  export default {
    name: "ConfirmOrder",
    components: {
        ElDialog,
        ElInput,
        ElSelect,
        ElOption,
        SldAddressOperation,
        AddInvoice
    },
    setup() {
      const route = useRoute();
      const router = useRouter();
      const store = useStore()
      const addressVisibleFlag = ref(false);
      const pageLoading = ref(false);
      const showOtherAddr = ref(false);
      const refreshAddr = ref(false);
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const not_select = require("../../assets/buy/not_select.png");
      const selected = require("../../assets/buy/selected.png");
      const invoice_selected = require("../../assets/buy/invoice_selected.png");
      const couponCode = ref([])
      const platformCouponCode = ref("")
      const navlist = reactive({ data: [] });
      const show_change_address = ref(false);
      const edit_voice_dialog = ref(false);
      const add_voice_content = ref(false);
      const out_stock_dialog = ref(false);
      const add_invoice_content = ref(false);
      const type = ref("add");
      const select_invoice_str = ref("");
      const timer = ref(0); //定时器
      const no_good_info = reactive({ data: {} });
      const show_select_address = ref(false);
      const pre_params = reactive({ data: {} });
      const orderAddress = reactive({ data: "" });
      const address_list = reactive({ data: [] });
      const goods_data = reactive({ data: [] });
      const configInfo = ref(store.state.configInfo)
      const current_address_index = ref(0);
      const curLang = computed(() => store.state.languageType);
      const isBottomShow = ref(false);
      const select_invoice_info = reactive({
        invoiceId: "",
        invoiceContent: "",
        invoiceTitle: ""
      });
      const selCouponStore = reactive({
        store: {},
        couponCode: ''
      })
      const rules = ref('')
      const invoice_list = reactive({ data: [] }); //发票列表
      const intRuleList = ref([])
      const invoice_info = reactive({
        data: {
          //新增发票信息
          bankAccount: "", //银行帐户(专用发票）
          bankName: "", //开户银行(专用发票）
          invoiceContent: 1, //发票内容：1-商品明细；2-商品类别
          invoiceTitle: "", //发票抬头(通用信息）
          invoiceType: 1, //发票类型：1-普通发票；2-增值税发票
          isDefault: false, //是否默认发票：0-非默认发票，1-默认发票
          receiverAddress: "", //收票人详细地址(通用信息）
          receiverEmail: "", //	收票邮箱(通用信息）
          receiverMobile: "", //收票人手机号(通用信息）
          receiverName: "", //收票人姓名(通用信息）
          registerAddr: "", //注册地址(专用发票）
          registerPhone: "", //注册电话(专用发票）
          taxCode: "", //纳税人识别号(通用信息）
          titleType: 1, //抬头类型：1-个人；2-公司
          companyName: "" //单位名称
        }
      });
      // 重置发票信息
      const resetVoiceInfo = () => {
        invoice_info.data.bankAccount = "";
        invoice_info.data.bankName = "";
        invoice_info.data.invoiceContent = 1;
        invoice_info.data.invoiceTitle = "";
        invoice_info.data.invoiceType = 1;
        invoice_info.data.isDefault = false;
        invoice_info.data.receiverAddress = "";
        invoice_info.data.receiverEmail = "";
        invoice_info.data.receiverMobile = "";
        invoice_info.data.receiverName = "";
        invoice_info.data.registerAddr = "";
        invoice_info.data.registerPhone = "";
        invoice_info.data.taxCode = "";
        invoice_info.data.titleType = 1;
        invoice_info.data.companyName = "";
      };
      const order_settle_info = reactive({
        totalAmount: "",
        totalDiscount: "",
        platformCouponList: [],
        platformCouponCode: ""
      });
      const allData = reactive({ data: {} })
      const showIntList = ref(false)
      const showIntRule = ref(false)
      const chosenInt = ref(0)
      const tmpInt = ref(0)
      const virtual = reactive({
        isVG: null,
        virtualPre: []
      })
      const preMsgErr = reactive({
        index: null,
        errMsg: ''
      })
      const isPreventClick = ref(false)
      const reserveInfoList = ref([])


      const changeCoupon = (storeItem, index) => {
        console.log(storeItem, index)
        selCouponStore.store = storeItem
        selCouponStore.couponCode = couponCode.value[index]
        confirmOrder(2)

      }

      const showAddAddress = () => {
          addressVisibleFlag.value = true;
          add_voice_content.value = false;
      };

      //用于切换地址，使用优惠券，获取信息，运费等
      const confirmOrder = type => {
        let param = {};
        param.source = type;
        param.addressId = orderAddress.data.addressId;
        param.platformCouponCode = platformCouponCode.value == L["不使用优惠券"] ? "" : platformCouponCode.value;

        let storeInfoList = [];
        // if (goods_data.data.length) {
        goods_data.data.map(item => {
          let storeitem = {};
          if (selCouponStore.store.storeId == item.storeId) {
            storeitem.remark = selCouponStore.store.remark;
            storeitem.storeCouponCode = selCouponStore.couponCode == L["不使用优惠券"] ? "" : selCouponStore.couponCode;
            storeitem.storeId = item.storeId;
          } else {
            storeitem.remark = item.remark;
            storeitem.storeCouponCode = item.couponCode
            storeitem.storeId = item.storeId;

          }
          storeInfoList.push(storeitem);
        });

        param.storeInfoList = storeInfoList;
        if (pre_params.data.ifcart == "true") {
          //来自于购物车
          param.isCart = true;
        } else {
          //立即购买
          param.productId = pre_params.data.productId;
          param.number = pre_params.data.numbers;
          param.isCart = false;
        }

        if (chosenInt.value > 0) {
          param.integral = chosenInt.value
        }

        if (route.query.isAloneBuy) {
          param.isAloneBuy = true
        }



        if (pre_params.data.isAloneBuy) {
          param.isAloneBuy = true
        }

        proxy.$post(
          "v3/business/front/orderOperate/confirm",
          param,
          "application/json"
        ).then(res => {
            pageLoading.value = false;
            // proxy.$loadingInstance.close();
          if (res.state == 200) {
            if (type == 1) {
              let errorGoods = res.data.errorGoodsId || [];
              goods_data.data = res.data.storeGroupList;
              console.log(goods_data.data, errorGoods)
              goods_data.data.map((item, i) => {
                item.remark = "";
                let default_coupon = item.availableCouponList.filter(function (
                  coupon
                ) {
                  return coupon.checked == true;
                });
                if (default_coupon.length > 0) {
                  item.couponCode = default_coupon[0].couponCode;
                  couponCode.value[i] = default_coupon[0].couponCode
                } else {
                  item.couponCode = "";
                  couponCode.value[i] = ""
                }
                item.productList.map(product => {
                  if (errorGoods.includes(product.goodsId)) {
                    product.errorGoods = true;
                  }else {
                    product.errorGoods = false;
                  }
                })

              });
            } else {
              let tmpRes = res.data.storeGroupList
              goods_data.data.map((item, index) => {
                let default_coupon = tmpRes[index].availableCouponList.filter((coupon) => coupon.checked == true)
                if (default_coupon.length > 0) {
                  item.couponCode = default_coupon[0].couponCode;
                  couponCode.value[index] = default_coupon[0].couponCode
                } else {
                  item.couponCode = "";
                  couponCode.value[index] = ""
                }

                item.expressFee = tmpRes[index].expressFee
                item.totalDiscount = tmpRes[index].totalDiscount
              })
            }
            allData.data = res.data

            order_settle_info.totalAmount = res.data.totalAmount;
            order_settle_info.platformCouponList = res.data.availableCouponList;
            order_settle_info.totalDiscount = res.data.totalDiscount;

            //处理平台优惠券
            let default_plat_coupon = order_settle_info.platformCouponList.filter(
              function (coupon) {
                return coupon.checked == true;
              }
            );
            if (default_plat_coupon.length > 0) {
              order_settle_info.platformCouponCode =
                default_plat_coupon[0].couponCode;
            } else {
              order_settle_info.platformCouponCode = "";
            }

            if (type == 1) {
              virtual.isVG = allData.data.isVirtualGoods
              virtual.virtualPre = allData.data.reserveNameList.map(item => {
                item.reserveValue = ''
                return item
              })
            }

            if (res.data.deliverIsSupport == 'Y') {
              isBottomShow.value = true;
            }else {
              isBottomShow.value =false;
              ElMessage.error(res.data.deliverErrorInfo);
            }


          } else {
            ElMessage.error(res.msg);
          }
        })

      };
      //获取地址列表
      const getAddressList = () => {
        proxy
          .$get("v3/member/front/memberAddress/list")
          .then(res => {
            if (res.state == 200) {
              address_list.data = res.data.list;
              if (res.data.list.length > 0 && !orderAddress.data.addressId) {
                var default_address = res.data.list.filter(function (item) {
                  return item.isDefault == 1;
                });
                if (default_address.length > 0) {
                  orderAddress.data = default_address[0];
                } else {
                  orderAddress.data = res.data.list[0];
                }
                if (localStorage.getItem('addressId')) {
                  let addressID = localStorage.getItem('addressId')
                    res.data.list.map((item, idx) => {
                        if (item.addressId == addressID) {
                            orderAddress.data = item;
                            current_address_index.value = idx;
                        }
                    })
                }
              }

              confirmOrder(1);
            } else {
              confirmOrder(2);
              ElMessage.error(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      //切换地址
      const chooseOther = () => {
        showOtherAddr.value = !showOtherAddr.value;
      };
      //切换地址
      const selectAddress = index => {
        current_address_index.value = index;
      };
      //选择地址
      const chooseAddress = index => {
          if (current_address_index.value != index) {
              current_address_index.value = index;
              showOtherAddr.value = false;
              confirmChangeAddress();
          }
      };
      //确认修改地址
      const confirmChangeAddress = () => {
        orderAddress.data = address_list.data[current_address_index.value];
        show_select_address.value = false;
        confirmOrder(2);
        localStorage.setItem('addressId', orderAddress.data.addressId)
      };
      //弹出地址选择框
      const changeAddress = () => {
        getAddressList();
        show_select_address.value = true;
      };
      //弹出发票选择框
      const showVoiceDialog = () => {
        edit_voice_dialog.value = true;
        getVoiceList();
      };
      const showAddvoice = () => {
          add_invoice_content.value = true;
          edit_voice_dialog.value = false;
      };
      //获取发票列表
      const getVoiceList = () => {
        proxy
          .$get("v3/member/front/memberInvoice/list")
          .then(res => {
            if (res.state == 200) {
              invoice_list.data = res.data.list;
              let hasDefault = res.data.list.filter(i=>i.isDefault == '1')
              select_invoice_str.value=hasDefault.length>0?`${hasDefault[0].invoiceTitle},${hasDefault[0].invoiceContent},${hasDefault[0].invoiceId}`:''

              // if (res.data.list.length > 0) {
              //   add_voice_content.value = false;
              // } else {
              //   add_voice_content.value = true;
              // }


              // if (!allData.data.isVatInvoice) {
              //   invoice_list.data = res.data.list.filter(i => i.invoiceType != 2)
              // }
            } else {
              ElMessage.error(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      const removeAddvoice = () => {
        select_invoice_info.invoiceId = false;
        select_invoice_info.invoiceContent = '';
        select_invoice_info.invoiceTitle = '';
        select_invoice_str.value = '';
        edit_voice_dialog.value = false;
      }
      //切换发票内容
      const changeInvoiceContent = invoiceContent => {
        invoice_info.data.invoiceContent = invoiceContent;
      };
      //切换发票抬头
      const changeInvoiceTitleType = titleType => {
        invoice_info.data.titleType = titleType;
      };
      //切换发票类型
      const changeInvoiceType = invoiceType => {
        invoice_info.data.invoiceType = invoiceType;
      };


      watch(invoice_info.data, () => {
        invoice_info.data.registerPhone = invoice_info.data.registerPhone.toString().substring(0, 11)
        invoice_info.data.receiverMobile = invoice_info.data.receiverMobile.toString().substring(0, 11)
        invoice_info.data.bankAccount = invoice_info.data.bankAccount.toString().substring(0, 19)
      })

      const invoiceCheck = (type) => {
        var reg = new RegExp("^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-z]{2,}$");
        switch (type) {
          case 'receiverEmail': {
            if (invoice_info.data.receiverEmail && !reg.test(invoice_info.data.receiverEmail)) {
              invoice_info.data.receiverEmail = ''
              ElMessage.error(L["邮箱格式不正确，请重新输入！"]);
            }
            break;
          }

          case 'taxCode': {
            let regExp = /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/;
            if (invoice_info.data.taxCode && !regExp.test(invoice_info.data.taxCode)) {
              invoice_info.data.taxCode = ''
              ElMessage.error(L["请填写正确的税号"]);
            }
            break;
          }

          case 'registerPhone': {
            if (invoice_info.data.registerPhone && proxy.$checkMobile(invoice_info.data.registerPhone) != true) {
              invoice_info.data.registerPhone = ""
              ElMessage.error("请填正确填写注册电话");
            }
            break;
          }

          case 'receiverMobile': {
            if (invoice_info.data.receiverMobile && proxy.$checkMobile(invoice_info.data.receiverMobile) != true) {
              invoice_info.data.receiverMobile = ""
              ElMessage.error("请填正确填写收票人电话");
            }
            break;
          }
        }
      }




      //新增发票
      const confirmAddVoice = () => {
        if (invoice_info.data.titleType == 1) {
          if (invoice_info.data.invoiceTitle == "") {
            ElMessage.error(L["请填写发票抬头"]);
            return;
          }
          var reg = new RegExp(
            "^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-z]{2,}$"
          );
          if (invoice_info.data.receiverEmail == "") {
            ElMessage.error(L["请填写收票邮箱"]);
            return;
          }
          if (!reg.test(invoice_info.data.receiverEmail)) {
            ElMessage.error(L["邮箱格式不正确，请重新输入！"]);
            return;
          }
        } else if (invoice_info.data.titleType == 2) {
          if (invoice_info.data.companyName == "") {
            ElMessage.error(L["请填写单位名称"]);
            return;
          }
          if (invoice_info.data.taxCode == "") {
            ElMessage.error(L["请填写纳税人税号"]);
            return;
          }
          let regExp = /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/;
          if (regExp.test(invoice_info.data.taxCode) == false) {
            ElMessage.error(L["请填写正确的税号"]);
            return;
          }
          if (invoice_info.data.invoiceType == 1) {
            if (invoice_info.data.receiverEmail == "") {
              ElMessage.error(L["请填写收票邮箱"]);
              return;
            }
          } else {
            if (invoice_info.data.registerAddr == "") {
              ElMessage.error(L['"请填写注册地址"']);
              return;
            }
            if (invoice_info.data.registerPhone == "") {
              ElMessage.error(L["请填写注册电话"]);
              return;
            }
            if (invoice_info.data.bankName == "") {
              ElMessage.error(L["请填写开户银行"]);
              return;
            }
            if (invoice_info.data.bankAccount == "") {
              ElMessage.error("请填写银行账户");
              return;
            }
            if (invoice_info.data.receiverName == "") {
              ElMessage.error(L["请填写收票人姓名"]);
              return;
            }
            if (invoice_info.data.receiverMobile == "") {
              ElMessage.error(L["请填写收票人电话"]);
              return;
            }
            if (invoice_info.data.receiverAddress == "") {
              ElMessage.error(L["请填写收票人地址"]);
              return;
            }
          }
        }
        var param = {};
        param.titleType = invoice_info.data.titleType;
        param.invoiceType = invoice_info.data.invoiceType;
        param.invoiceContent = invoice_info.data.invoiceContent;
        if (invoice_info.data.isDefault) {
          param.isDefault = 1;
        } else {
          param.isDefault = 0;
        }
        if (invoice_info.data.invoiceType != 2) {
          param.receiverEmail = invoice_info.data.receiverEmail;
        }
        if (invoice_info.data.titleType == 1) {
          param.invoiceTitle = invoice_info.data.invoiceTitle;
        } else {
          param.taxCode = invoice_info.data.taxCode;
          param.invoiceTitle = invoice_info.data.companyName;
          if (invoice_info.data.invoiceType == 2) {
            param.registerAddr = invoice_info.data.registerAddr;
            param.registerPhone = invoice_info.data.registerPhone;
            param.bankName = invoice_info.data.bankName;
            param.bankAccount = invoice_info.data.bankAccount;
            param.receiverName = invoice_info.data.receiverName;
            param.receiverMobile = invoice_info.data.receiverMobile;
            param.receiverAddress = invoice_info.data.receiverAddress;
          }
        }
        proxy
          .$post("v3/member/front/memberInvoice/add", param)
          .then(res => {
            if (res.state == 200) {
              if (res.state == 200) {
                select_invoice_info.invoiceTitle = invoice_info.data.titleType == 2 ? invoice_info.data.companyName : invoice_info.data.invoiceTitle;
                select_invoice_info.invoiceContent =
                  invoice_info.data.invoiceContent == 1 ? L["商品明细"] : L["商品类别"];
                select_invoice_info.invoiceId = res.data;
                edit_voice_dialog.value = false;
                add_voice_content.value = false;
                resetVoiceInfo();
                ElMessage.success({
                  message: res.msg,
                  type: "success"
                });
              }
            } else {
              ElMessage.error(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      //新增发票返回
      const voiceReturn = () => {
        if (invoice_list.data.length > 0) {
          add_voice_content.value = false;
        } else {
          edit_voice_dialog.value = false;
        }
        resetVoiceInfo();
      };
      //选择发票
      const handleChangeInvoice = voice => {
          let arr = voice.split(',');
        select_invoice_info.invoiceTitle = arr[0];
        select_invoice_info.invoiceContent = arr[1] == 1 ? L["商品明细"] : L["商品类别"];
        select_invoice_info.invoiceId = arr[2];
        edit_voice_dialog.value = false;
          select_invoice_str.value = voice;
      };
      //选择发票
      const chooseVoice = voice => {
        select_invoice_info.invoiceTitle = voice.invoiceTitle;
        select_invoice_info.invoiceContent =
          voice.invoiceContent == 1 ? L["商品明细"] : L["商品类别"];
        select_invoice_info.invoiceId = voice.invoiceId;
        edit_voice_dialog.value = false;
      };
      //返回购物车
      const goBack = () => {
        router.push('/cart/index');
      };
      //确认订单前校验商品是否可购买
      const checkOrder = () => {

        if (isPreventClick.value) {
          return
        }
        isPreventClick.value = true




        let param = {};
        if (pre_params.data.ifcart == "true") {
          param.isCart = true;
        } else {
          param.isCart = false;
          param.productId = pre_params.data.productId;
          param.number = pre_params.data.numbers;
        }

        if (route.query.isAloneBuy) {
          param.isAloneBuy = true
        }

        reserveInfoList.value = []
        //预留信息校验start
        if (virtual.isVG == 2 && virtual.virtualPre.length > 0) {
          for (let i = 0; i < virtual.virtualPre.length; i++) {

            let { reserveName, reserveType, reserveValue, reserveNameId, isRequired } = virtual.virtualPre[i]
            if (checkPreMsg(reserveType, reserveValue, reserveName, isRequired) == true) {
              reserveInfoList.value.push({ reserveName, reserveValue, reserveNameId })
            } else {
              preMsgErr.errMsg = checkPreMsg(reserveType, reserveValue, reserveName, isRequired)
              preMsgErr.index = i
              ElMessage.error(checkPreMsg(reserveType, reserveValue, reserveName, isRequired))
              isPreventClick.value = false
              return
            }

          }
        }
        //预留信息校验end

        if (virtual.isVG == 2) {
          param.reserveInfoList = reserveInfoList.value
        }


        proxy
          .$post(
            "v3/business/front/orderOperate/check",
            param,
            "application/json"
          )
          .then(res => {
            if (res.state == 267) {
              no_good_info.data = res.data;
              out_stock_dialog.value = true;
              isPreventClick.value = false
            } else if (res.state == 200) {
              goBuy();
            } else {
              ElMessage.error.error(res.msg)
              isPreventClick.value = false
            }
          })
      };
      //返回上一页‘
      const returnLastPage = () => {
        out_stock_dialog.value = false;
        router.go(-1);
      };
      //移除无货商品
      const clearFailureGoods = () => {
        proxy
          .$post("v3/business/front/cart/emptyInvalid")
          .then(res => {
            if (res.state == 200) {
              out_stock_dialog.value = false;
              confirmOrder(2);
              ElMessage.success(res.msg);
            } else {
              ElMessage.error(res.msg);
            }
          })
      };
      //跳转订单页
      const goBuy = () => {
        let param = {};
        param.source = 3;
        param.orderFrom = 1;
        if (virtual.isVG == 1) {
          param.addressId = orderAddress.data.addressId;
        }
        param.platformCouponCode = order_settle_info.platformCouponCode;
        let storeInfoList = [];
        goods_data.data.map(item => {
          let storeitem = {};
          storeitem.invoiceId = select_invoice_info.invoiceId;
          storeitem.remark = item.remark;
          storeitem.storeCouponCode = item.couponCode;
          storeitem.storeId = item.storeId;
          storeInfoList.push(storeitem);
        });
        param.storeInfoList = storeInfoList;
        if (pre_params.data.ifcart == "true") {
          //来自于购物车
          param.isCart = true;
        } else {
          //立即购买
          param.productId = pre_params.data.productId;
          param.number = pre_params.data.numbers;
          param.isCart = false;
        }

        if (chosenInt.value > 0) {
          param.integral = chosenInt.value
        }

        if (route.query.isAloneBuy) {
          param.isAloneBuy = true
        }

        if (virtual.isVG == 2) {
          param.reserveInfoList = reserveInfoList.value
        }

        proxy
          .$post(
            "/v3/business/front/orderOperate/submit",
            param,
            "application/json"
          )
          .then(res => {
            if (res.state == 200) {
              let paySn = res.data.paySn;
              proxy.$sldStatEvent({ behaviorType: 'buy' });
              timer.value = setInterval(() => {
                queryPayState(paySn);
              }, 200);
            } else {
              ElMessage.error(res.msg);
              isPreventClick.value = false
            }
          })

      };
      // 定时查询是否可以支付
      const queryPayState = paySn => {
        getPayInfo(paySn);
      };
      //获取订单支付数据
      const getPayInfo = paySn => {
        proxy
          .$get("v3/business/front/orderPay/payInfo", {
            paySn: paySn,
            payFrom: 1
          })
          .then(res => {
            if (res.state == 200) {
              isPreventClick.value = false
              if (res.data.dealState == 3) {
                router.replace({
                  path: "Pay",
                  query: {
                    paySn: paySn,
                    payFrom: 1,
                    ifcart: pre_params.data.ifcart
                  }
                });
                //清除定时器
                if (timer.value) {
                  clearInterval(timer.value);
                  timer.value = null;
                }
              } else if (res.data.dealState == 2) {
                ElMessage.error(L["提交订单失败，请稍后重试"]);
                isPreventClick.value = false
                //清除定时器
                if (timer.value) {
                  clearInterval(timer.value);
                  timer.value = null;
                }
              }
            } else if (res.state == 267) {
              ElMessage.success(res.msg + L[",2s后自动跳转订单列表"]);
              //清除定时器
              if (timer.value) {
                clearInterval(timer.value);
                timer.value = 0;
              }
              setTimeout(() => {
                goOtherApp({
                  type: 'Ordinary'
                });
              }, 2000);
            } else {
              //清除定时器
              isPreventClick.value = false
              if (timer.value) {
                clearInterval(timer.value);
                timer.value = 0;
              }
              ElMessage.error(res.msg);
            }
          })
      };
      onUnmounted(() => {
        if (timer.value) {
          clearInterval(timer.value);
          timer.value = 0;
        }
      });
      //关闭弹窗
      const close = () => {
        addressVisibleFlag.value = false;
      };
      //关闭弹窗
      const closeDia = () => {
          add_invoice_content.value = false;
      };
      //弹出新建地址窗口
      const addAddress = () => {
        addressVisibleFlag.value = true;
      };
      const refreshInvoice = (invoiceInfo) => {
          select_invoice_info.invoiceTitle = invoiceInfo.invoiceTitle;
          select_invoice_info.invoiceContent = invoiceInfo.invoiceContent == 1 ? L["商品明细"] : L["商品类别"];
          select_invoice_info.invoiceId = invoiceInfo.invoiceId;
          select_invoice_str.value = `${invoiceInfo.invoiceTitle},${invoiceInfo.invoiceContent},${invoiceInfo.invoiceId}`;
          getVoiceList();
      };
      const refreshAddress = (addressInfo, addressId) => {
        orderAddress.data = addressInfo;
        orderAddress.data.addressId = addressId;
        localStorage.setItem('addressId', orderAddress.data.addressId)
          getAddressList();
      };

      const integralEng = () => {
        chosenInt.value = tmpInt.value
        if (chosenInt.value > 0) {
          confirmOrder(2)
        }
        showIntList.value = false
      }

      const integralAban = () => {
        if (chosenInt.value > 0) {
          chosenInt.value = 0
          confirmOrder(2)
        }
        showIntList.value = false
      }


      // 积分抵扣相关参数
      const getIntRule = () => {
        proxy.$get('v3/system/front/setting/getSettings', { names: 'integral_conversion_ratio,integral_use_lowest_amount,integral_max_deduct_rate,integral_cash_out_is_enable' }).then(res => {
          if (res.state == 200) {
            intRuleList.value = res.data
          }
        })
      }

      const shIntRule = () => {
        showIntRule.value = true
      }

      //校验预留信息
      const checkPreMsg = (type, value, name, isRequired) => {
        switch (type) {
          case 1: {
            if (isRequired == 1) {
              return proxy.$checkPhone(value)
            } else {
              let regMobile = /(1[3-9]\d{9}$)/;
              if (value && !regMobile.test(value)) {
                return `请输入正确的${name}!`;
              } else {
                return true
              }
            }

            break;
          }
          case 2: {
            if (isRequired == 1) {
              return proxy.$checkIdentity(value)
            } else {
              if (value) {
                let reg18 =
                  /^[1-9][0-9]{5}(18|19|20)[0-9]{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)[0-9]{3}([0-9]|(X|x))/
                let reg15 =
                  /^[1-9][0-9]{5}[0-9]{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)[0-9]{2}[0-9]/
                if (reg18.test(value) || reg15.test(value)) {
                  return true;
                } else {
                  return `请输入正确的${name}`
                }
              } else {
                return true
              }

            }

            break
          }

          case 3: {
            let regNum = /[0-9]+(.[0-9]+)?/
            console.log(value, 'w123')
            if (isRequired == 1) {
              if (!value) {
                return `请输入${name}`
              } else if (!regNum.test(value)) {
                return `请输入正确的${name}`
              } else {
                return true
              }

            } else {
              return true
            }
            break;
          }
          case 4: {
            if (isRequired == 1) {
              if (!value) {
                return `请输入${name}`
              } else if (type == 4) {
                return true
              }
            } else {
              return true
            }
            break;
          }
          case 5: {
            if (isRequired == 1) {
              return proxy.$checkEmail(value)
            } else {
              let reg =
                /^([a-zA-Z0-9]+[-_.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[-_.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/;
              if (value && !reg.test(value)) {
                return `请输入正确的${name}!`;
              } else {
                return true;
              }
            }

            break
          }
        }
      }


      onMounted(() => {
          pageLoading.value = true;
        pre_params.data = route.query;
        getAddressList();
        getIntRule()
      });
      return {
          pageLoading,
          showOtherAddr,
        navlist,
        not_select,
        selected,
        show_change_address,
        edit_voice_dialog,
        showVoiceDialog,
        invoice_selected,
        add_voice_content,
          add_invoice_content,
          refreshAddr,
        showAddvoice,
        removeAddvoice,
        out_stock_dialog,
        orderAddress,
        changeAddress,
        show_select_address,
        address_list,
        current_address_index,
        selectAddress,
          chooseOther,
          chooseAddress,
        confirmChangeAddress,
        goods_data,
        pre_params,
        invoice_info,
        changeInvoiceContent,
        changeInvoiceTitleType,
        changeInvoiceType,
        confirmAddVoice,
        invoice_list,
          select_invoice_str,
        select_invoice_info,
        voiceReturn,
          handleChangeInvoice,
        chooseVoice,
        order_settle_info,
        confirmOrder,
        checkOrder,
        goBuy,
        returnLastPage,
        clearFailureGoods,
        no_good_info,
        addressVisibleFlag,
        close,
          closeDia,
        type,
          refreshInvoice,
        refreshAddress,
        addAddress,
        couponCode,
        platformCouponCode,
        configInfo,
        rules,
        changeCoupon,
          showAddAddress,
        intRuleList,
        allData,
        showIntList,
        chosenInt,
        integralEng,
        integralAban,
        showIntRule,
        shIntRule,
        L,
        tmpInt,
        invoiceCheck,
        virtual,
        preMsgErr,
        goBack,
        curLang,
        isBottomShow,
      };
    }
  };
</script>

<style lang="scss" scoped>
  @import "../../style/confirmOrder.scss";
</style>
<style lang="scss">
.store_coupon_con2 {
    height: 99px;
    padding-left: 20px;
    padding-right: 20px;
    .el-input {
        .el-textarea__inner {
            height: 60px;
            font-size: 14px;
            font-weight: 400;
            color: #9F9F9F;
            //line-height: 20px;
        }
    }
}
.el-dialog__header {
    background: #F5F5F5;
}
.el-dialog__close {
    font-size: 22px;
}
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }


  .voice_width {
    width: 605px !important;
  }


  .out_store_width {
    width: 480px !important;
  }

  .select_address_width {
    width: 500px !important;
  }

  .el-dialog__body {
    padding-top: 10px;
  }

  .el-input__inner {
    font-size: 12px;
    line-height: 1px !important;
    /**
* 解决el-input设置类型为number时，中文输入法光标上移问题
**/
  }


  .el-select .el-input__inner:focus {
    border-color: #e2231a;
  }

  .el-select .el-input.is-focus .el-input__inner {
    border-color: #e2231a;
  }

  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    border-color: #e2231a;
    outline: 0;
  }

  .validateMessage {
    font-size: 12px;
    color: #e2231a;
    margin-top: 5px;
    padding-left: 80px;
    height: 12px;
  }

  .giftProduct {
    display: inline-block;
    border: 1px solid #e2231a;
    color: #e2231a;
    width: 33px;
    height: 16px;
    border-radius: 3px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin-right: 4px;
  }

  .integral_dialog {
    .el-dialog__body {
      padding: 0;
    }
  }

  #pre_tag_input {
    height: 30px;
    width: 320px;
  }

  .preMsgErr {
    color: #e2231a;
    margin-left: 13px;
  }
</style>
